import React, { useState, useEffect, lazy, Suspense } from 'react';
//THEME
import { ThemeProvider } from 'styled-components';
//ROUTING
import {
    Navigate,
    Outlet,
    BrowserRouter as Router,
    Route,
    Routes,
} from 'react-router-dom';
//AUTH
import { useUserAuth } from './context/UserAuthContext';
//STYLE
import { GlobalStyle } from './styles/Global';
import { darkTheme, lightTheme } from './styles/Theme';
//COMPONENTS
import LoadingPage from './components/LoadingPage';

const Hero = lazy(() => import('./pages/landingpage/hero'));
const LoginPage = lazy(() => import('./pages/userAuth/login'));
const SignupPage = lazy(() => import('./pages/userAuth/signup'));
const CheckoutPage = lazy(() => import('./pages/userAuth/checkout'));
const PostCheckoutPage = lazy(() => import('./pages/userAuth/postCheckout'));
const PwReset = lazy(() => import('./pages/userAuth/passwordReset'));
const PwRequestPage = lazy(() =>
    import('./pages/userAuth/passwordResetRequest')
);
const PrivacyPolicy = lazy(() => import('./pages/landingpage/privacy-policy'));
const CookiePolicy = lazy(() => import('./pages/landingpage/cookie-policy'));
const RiskDisclaimer = lazy(() =>
    import('./pages/landingpage/risk-disclaimer')
);
const TermsPolicy = lazy(() => import('./pages/landingpage/terms-policy'));
const Contact = lazy(() => import('./pages/landingpage/contact'));
const FAQ = lazy(() => import('./pages/landingpage/faq'));
const FZF = lazy(() => import('./pages/landingpage/fourzerofourpage'));
const Roadmap = lazy(() => import('./pages/landingpage/roadmap'));
const Pricing = lazy(() => import('./pages/landingpage/pricing'));
// Dashboard
const Dashboard = lazy(() => import('./pages/dashboard/dashboard'));
const Labels = lazy(() => import('./pages/dashboard/labels'));
const Rules = lazy(() => import('./pages/dashboard/rules'));
const API = lazy(() => import('./pages/dashboard/connections'));
const Settings = lazy(() => import('./pages/dashboard/settings'));
const Trades = lazy(() => import('./pages/dashboard/trades/trades'));
const TradesSingle = lazy(() =>
    import('./pages/dashboard/trades/trades.single')
);
const JournalPage = lazy(() => import('./pages/dashboard/journal/journal'));
const JournalDayViewPage = lazy(() =>
    import('./pages/dashboard/journal/journal.dayView')
);
const Reports = lazy(() => import('./pages/dashboard/reports/reports'));
const ReportsDaily = lazy(() =>
    import('./pages/dashboard/reports/reports.day')
);
const ReportsHourly = lazy(() =>
    import('./pages/dashboard/reports/reports.hour')
);
const ReportsLabel = lazy(() =>
    import('./pages/dashboard/reports/reports.label')
);
const ReportsMonthly = lazy(() =>
    import('./pages/dashboard/reports/reports.month')
);
const ReportsTicker = lazy(() =>
    import('./pages/dashboard/reports/reports.ticker')
);
const ReportHolding = lazy(() =>
    import('./pages/dashboard/reports/reports.holding')
);
const Screener = lazy(() => import('pages/dashboard/screener'));
const Calculator = lazy(() => import('pages/dashboard/calculator'));
const Education = lazy(() => import('pages/dashboard/education/education'));

//TODO: Move this to a separate file
export const ThemeContext = React.createContext(null);

function App() {
    const [theme, setTheme] = useState('dark');
    const themeStyle = theme === 'dark' ? darkTheme : lightTheme;
    const { currentUser } = useUserAuth();

    const ProtectedRoute = ({ children }) => {
        if (currentUser) {
            if (currentUser?.uid === undefined || currentUser === null) {
                return <Navigate to={'/'} replace />;
            }
            return children ? children : <Outlet />;
        }
    };

    useEffect(() => {
        if (currentUser !== null && currentUser !== undefined) {
            <Navigate
                to={'/user?uid=' + currentUser.uid + '/overview'}
                replace
            />;
        } else {
            <Navigate to={'/'} replace />;
        }
    }, [currentUser]);

    //TODO: Clean this up, routes are getting messy
    return (
        <ThemeContext.Provider value={{ setTheme, theme }}>
            <ThemeProvider theme={themeStyle}>
                <GlobalStyle />

                <Suspense fallback={<LoadingPage />}>
                    <Router>
                        <Routes>
                            <Route path="*" element={<FZF />} />
                            <Route exact path="/">
                                <Route index element={<Hero />} exact />
                                <Route
                                    path={'privacy-policy'}
                                    element={<PrivacyPolicy />}
                                    exact
                                />
                                <Route
                                    path={'risk-disclaimer'}
                                    element={<RiskDisclaimer />}
                                    exact
                                />
                                <Route
                                    path={'cookie-policy'}
                                    element={<CookiePolicy />}
                                    exact
                                />
                                <Route
                                    path={'terms'}
                                    element={<TermsPolicy />}
                                    exact
                                />
                                <Route
                                    path={'contact'}
                                    element={<Contact />}
                                    exact
                                />
                                <Route path={'faq'} element={<FAQ />} exact />
                                <Route path={'404'} element={<FZF />} exact />
                                <Route
                                    path={'pricing'}
                                    element={<Pricing />}
                                    exact
                                />
                                <Route
                                    path={'roadmap'}
                                    element={<Roadmap />}
                                    exact
                                />
                                <Route exact path="/auth">
                                    <Route
                                        path={'login'}
                                        element={<LoginPage />}
                                        exact
                                    />
                                    <Route
                                        path={'signup'}
                                        element={<SignupPage />}
                                        exact
                                    />
                                    <Route
                                        path={'password-request'}
                                        element={<PwRequestPage />}
                                        exact
                                    />
                                    <Route
                                        path={'password-reset'}
                                        element={<PwReset />}
                                        exact
                                    />
                                    <Route
                                        path={'checkout'}
                                        //TODO: is this search needed? or does URLSearchParams work? too
                                        search={'?uid=:uid'}
                                        element={<CheckoutPage />}
                                        exact
                                    />
                                    <Route
                                        path={'post_checkout'}
                                        //TODO: is this search needed? or does URLSearchParams work? too
                                        search={'?uid=:uid&checkout=cancelled'}
                                        element={<PostCheckoutPage />}
                                        exact
                                    />
                                </Route>
                                <Route element={<ProtectedRoute />}>
                                    <Route exact path="/user/:uid">
                                        {/* Dashboard */}
                                        <Route
                                            path={'overview'}
                                            element={<Dashboard />}
                                            exact
                                        />
                                        <Route
                                            path={'connections'}
                                            element={<API />}
                                            exact
                                        />
                                        <Route
                                            path={'settings'}
                                            element={<Settings />}
                                            exact
                                        />
                                        <Route
                                            path="labels"
                                            index
                                            element={<Labels />}
                                            exact
                                        />
                                        <Route
                                            path="rules"
                                            index
                                            element={<Rules />}
                                            exact
                                        />
                                        <Route
                                            path="screener"
                                            index
                                            element={<Screener />}
                                            exact
                                        />
                                        <Route
                                            path="calc"
                                            index
                                            element={<Calculator />}
                                            exact
                                        />
                                        {/* Education */}
                                        <Route path="edu">
                                            <Route
                                                index
                                                element={<Education />}
                                                exact
                                            />
                                        </Route>
                                        {/* Trades */}
                                        <Route path="trades">
                                            <Route
                                                index
                                                element={<Trades />}
                                                exact
                                            />
                                            <Route
                                                path="single"
                                                index
                                                element={<TradesSingle />}
                                                exact
                                            />
                                        </Route>
                                        {/* Journal */}
                                        <Route path="journal">
                                            <Route
                                                index
                                                element={<JournalPage />}
                                                exact
                                            />
                                            <Route
                                                path="daily"
                                                index
                                                element={<JournalDayViewPage />}
                                                exact
                                            />
                                        </Route>
                                        <Route path="report">
                                            {/* Reports */}
                                            <Route
                                                index
                                                element={<Reports />}
                                                exact
                                            />
                                            <Route
                                                path="hour"
                                                index
                                                element={<ReportsHourly />}
                                                exact
                                            />
                                            <Route
                                                path="day"
                                                index
                                                element={<ReportsDaily />}
                                                exact
                                            />
                                            <Route
                                                path="month"
                                                index
                                                element={<ReportsMonthly />}
                                                exact
                                            />
                                            <Route
                                                path="label"
                                                index
                                                element={<ReportsLabel />}
                                                exact
                                            />
                                            <Route
                                                path="ticker"
                                                index
                                                element={<ReportsTicker />}
                                                exact
                                            />
                                            <Route
                                                path="holding"
                                                index
                                                element={<ReportHolding />}
                                                exact
                                            />
                                        </Route>
                                    </Route>
                                </Route>
                            </Route>
                        </Routes>
                    </Router>
                </Suspense>
            </ThemeProvider>
        </ThemeContext.Provider>
    );
}
export default App;
