import React, { createContext, useContext, useState } from 'react';
import useSessionStorage from 'components/Dashboard/utils/Helpers/sessionStorageHook';

const StateContext = createContext();

export const StateContextProvider = ({ children }) => {
    const [activeSidebar, setActiveSidebar] = useState(true);
    const [subnav, setSubnav] = useState();

    /**
     * TODO: Cleanup
     * TODO: Devide this context file into sub files so that
     * we don't need to import all context everywhere
     * */
    //PopUp states
    const [modalOpen_areYouSure, setModalOpen_areYouSure] = useState([
        false,
        '',
    ]);
    const [modalOpen_deleteAccount, setModalOpen_deleteAccount] =
        useState(false);
    const [modalOpen_cancelSub, setModalOpen_cancelSub] = useState(false);
    const [modalOpen_changePw, setModalOpen_changePw] = useState(false);
    const [modalOpen_changeEmail, setModalOpen_changeEmail] = useState(false);
    const [modalOpen_ImportStart, setModalOpen_ImportStart] = useState(false);
    const [
        modalOpen_changeSingleTradeSaved,
        setModalOpen_changeSingleTradeSaved,
    ] = useState(false);

    //All Labels from database
    const [customLabels, setAllLabels] = useState({});

    //Context for trade tables and account display
    const [accountData, setAccountData] = useState(undefined);
    const [trades, setTrades] = useState(undefined);
    const [userTradeData, setUserTradeData] = useState(undefined);

    //Context for reports
    const [reportHourly, setReportHourly] = useState(undefined);
    const [reportDaily, setReportDaily] = useState(undefined);
    const [reportMonthly, setReportMonthly] = useState(undefined);
    const [reportTicker, setReportTicker] = useState(undefined);

    const [sessionAccounts, setSessionAccounts] = useSessionStorage(
        'cryptodeck:accounts',
        []
    );
    const [sessionReport, setSessionReport] = useSessionStorage(
        'cryptodeck:reportData',
        []
    );

    return (
        <StateContext.Provider
            value={{
                //Sidebar states
                activeSidebar,
                setActiveSidebar,
                subnav,
                setSubnav,

                //PopUp states
                modalOpen_areYouSure,
                setModalOpen_areYouSure,
                modalOpen_deleteAccount,
                setModalOpen_deleteAccount,
                modalOpen_cancelSub,
                setModalOpen_cancelSub,
                modalOpen_changePw,
                setModalOpen_changePw,
                modalOpen_changeEmail,
                setModalOpen_changeEmail,
                modalOpen_ImportStart,
                setModalOpen_ImportStart,
                modalOpen_changeSingleTradeSaved,
                setModalOpen_changeSingleTradeSaved,

                //User data states
                customLabels,
                setAllLabels,
                accountData,
                setAccountData,

                //Report states
                reportHourly,
                setReportHourly,
                reportDaily,
                setReportDaily,
                reportMonthly,
                setReportMonthly,
                reportTicker,
                setReportTicker,

                //--------------------------------
                //TODO: This needs to be localStoraged
                trades,
                setTrades,
                userTradeData,
                setUserTradeData,

                //--------------------------------

                //Session states
                sessionAccounts,
                setSessionAccounts,
                sessionReport,
                setSessionReport,
            }}
        >
            {children}
        </StateContext.Provider>
    );
};

export const useStateContext = () => useContext(StateContext);
