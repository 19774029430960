import * as vars from './Variables';

export const lightTheme = {};
export const darkTheme = {
    color: {
        bg0: `${vars.darkTheme.bg0}`,
        bg1: `${vars.darkTheme.bg1}`,
        bg2: `${vars.darkTheme.bg2}`,
        bg3: `${vars.darkTheme.bg3}`,
        bg4: `${vars.darkTheme.bg4}`,
        bg5: `${vars.darkTheme.bg5}`,
        text: `${vars.darkTheme.text}`,
        textSecondary: `${vars.darkTheme.textSecondary}`,
        textSecondary_dark: `${vars.darkTheme.textSecondary_dark}`,
        primary: `${vars.darkTheme.primary}`,
        secondary: `${vars.darkTheme.sbuttonecondary}`,
        button: `${vars.darkTheme.lineColor}`,
        lineColor: `${vars.darkTheme.lineColor}`,
        gridLines: `${vars.darkTheme.gridLines}`,
        lossRed_500: `${vars.darkTheme.lossRed_500}`,
        winGreen_500: `${vars.darkTheme.winGreen_500}`,
    },
};

export const landingpage_lightTheme = {
    bg: 'rgb(255,255,255)',
    bgAlpha: 'rgba(250,250,250,.3)',
    bg2: 'rgb(245,245,245)',
    bg3: 'rgb(230,230,230)',
    text: 'rgb(45,45,45)',
    primary: 'rgb(4,65,98)',
};
export const landingpage_darkTheme = {
    bg: 'rgb(15,15,15)',
    bgAlpha: 'rgba(0,0,0,.3)',
    bg2: 'rgb(30,30,30)',
    bg3: 'rgb(50,50,50)',
    text: 'rgb(210,210,210)',
    primary: 'rgb(4,65,98)',
};
