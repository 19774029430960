import { css } from 'styled-components';

//TODO: Cleanup
//TODO: setUp Fonts and spacing for different screensize
//TODO: use thmee and --vars

export const darkTheme = {
    // bg0: '#111315',
    // bg1: '#181C1F',
    // bg2: '#1F2529',
    // bg3: '#272D32',
    // bg4: '#2E363C',
    // bg5: '#363F45',
    // bg6: '#3D474F',
    // bg7: '#455058',
    // bg8: '#4C5962',
    // bg9: '#53616C',
    // bg10: '#5B6A75',
    // bg11: '#62737F',

    // bg0: '#0d0d0d',
    // bg1: '#121214',
    // bg2: '#1b1b1f',
    // bg3: '#242429',
    // bg4: '#2d2d32',
    // bg5: '#36363c',
    // bg6: '#3f3f45',
    // bg7: '#48484f',
    // bg8: '#515158',
    // bg9: '#5a5a61',
    // bg10: '#63636a',
    // bg11: '#6c6c73',

    bg0: '#0d0d0d',
    bg1: '#121214',
    bg2: '#18181a',
    bg3: '#1e1e20',
    bg4: '#242426',
    bg5: '#2a2a2c',
    bg6: '#303032',
    bg7: '#363634',
    bg8: '#3c3c3e',
    bg9: '#424244',
    bg10: '#484846',
    bg11: '#4e4e4c',

    text: '#d2d2d2',
    // text: '#ededed',
    textSecondary_light: 'rgba(255, 255, 255, 0.8)',
    textSecondary: 'rgba(255, 255, 255, 0.6)',
    textSecondary_dark: 'hsla(240, 33%, 97%, 0.40)',
    primary: '#A8A29E',
    lineColor: '#f5f5fa66',

    gridLines: 'hsla(240, 33%, 97%, 0.20)',
    white_20: 'rgba(255, 255, 255, 0.2)',
    white_40: 'rgba(255, 255, 255, 0.4)',
    white_60: 'rgba(255, 255, 255, 0.6)',
    white_80: 'rgba(255, 255, 255, 0.8)',
    white_90: 'rgba(255, 255, 255, 0.9)',

    lossRed_100: '#F68E8E',
    lossRed_200: '#F47C7C',
    lossRed_300: '#F26969',
    lossRed_400: '#F05656',
    lossRed_500: '#ef4444',
    lossRed_600: '#ED3131',
    lossRed_700: '#EB1E1E',
    lossRed_800: '#E11414',
    lossRed_900: '#CE1212',

    winGreen_100: '#07EDA5',
    winGreen_200: '#07DA97',
    winGreen_300: '#06C689',
    winGreen_400: '#06B27B',
    winGreen_500: '#059669',
    winGreen_600: '#048B60',
    winGreen_700: '#047752',
    winGreen_800: '#036345',
    winGreen_900: '#024F37',

    slate_100: '#e0e3e8',
    slate_200: '#c1c7d1',
    slate_300: '#a2acb9',
    slate_400: '#8390a2',
    slate_500: '#64748b',
    slate_600: '#505d6f',
    slate_700: '#3c4653',
    slate_800: '#282e38',
    slate_900: '#14171c',

    errorOrange: '#AB7D00',
    labels: '#B5B5B5',
};
// FONTS
export const fontSize = {
    // text_xs: '12px',
    // text_sm: '14px',
    // text_base: '16px',
    // text_lg: '18px',
    // text_xl: '20px',
    // text_2xl: '24px',
    // text_3xl: '30px',

    text_3xs: '0.55rem' /* 8px */,
    text_2xs: '0.65rem' /* 10px */,
    text_xs: '0.8rem' /* 12px */,
    text_sm: '0.875rem' /* 14px */,
    text_base: '1rem' /* 16px */,
    text_lg: '1.125rem' /* 18px */,
    text_xl: '1.25rem' /* 20px */,
    text_2xl: '1.5rem' /* 24px */,
    text_3xl: '1.875rem' /* 30px */,
};

export const btnReset = css`
    font-family: inherit;
    outline: none;
    border: none;
    background: none;
    letter-spacing: inherit;
    color: inherit;
    font-size: inherit;
    text-align: inherit;
    padding: 0;
`;

/**
 * Screensizes used by coinmarketman
 * - Container inlc. sidebar is never bigger than 1600px wide
 * - Stops looking good at 1080px but is shrinkable until 890px
 * - Shrinks until 890px before changing to sidebar to topbar
 * - Smaller size has fixed max shrinkable size until 790px wide
 */

export const screenSize = {
    web: '1680px',
    tablet_horizontal: '1024px',
    tablet_vertical: '890px',
    phone: '428px',

    sm: '640px',
    // => @media (min-width: 640px) { ... }

    md: '768px',
    // => @media (min-width: 768px) { ... }

    lg: '1024px',
    // => @media (min-width: 1024px) { ... }

    xl: '1280px',
    // => @media (min-width: 1280px) { ... }

    xxl: '1536px',
    // => @media (min-width: 1536px) { ... }
};

// FONTS

export const webFonts = {
    text: '18px',
    h1: '40px',
    h2: '25px',
    h3: '20px',
};

export const tabletFonts = {
    text: '18px',
    h1: '35px',
    h2: '21px',
    h3: '18px',
};

export const phoneFonts = {
    text: '16px',
    h1: '30px',
    h2: '18px',
    h3: '25px',
};

//FIGMA COLORS

// COLORS
export const WHITE = '#ECEEFA';
export const GRAY = 'rgba(255, 255, 255, 0.8)';
export const BLACK = '#121212';
export const BLACKBLACK = '#000000';
export const PRIMARYCOLOR = '#044162';
export const PRIMARYCOLOR_LIGHT = '#086a9f';
export const PRIMARYCOLOR_DARK = '#01273b';
export const SECONDARYCOLOR = '#ade4e7';
export const SECONDARYCOLOR_LIGHT = '#e0ffff';
export const SECONDARYCOLOR_DARK = '#7cb2b5';
export const FORMBG = '#f7fafc;';
export const LINKCOLOR = '#b5d8ef';
