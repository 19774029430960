import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as vars from 'styles/Variables';
import styled from 'styled-components';

//TOAST
export const notify_success = (successMessage, position) => {
    toast.success(successMessage, {
        position: position
            ? toast.POSITION[position]
            : toast.POSITION.BOTTOM_RIGHT,
        toastId: 'success',
    });
};
export const notify_error = (errorMessage) => {
    let errText = errorMessage;
    toast.error(errText, {
        position: toast.POSITION.BOTTOM_RIGHT,
        toastId: 'error',
    });
};

export const StyledToast = styled(ToastContainer)`
    --toastify-icon-color-error: ${vars.darkTheme.lossRed_500};
    --toastify-icon-color-success: ${vars.darkTheme.winGreen_500};
    --toastify-icon-color-warning: ${vars.darkTheme.errorOrange};

    .Toastify__toast {
        background: ${vars.darkTheme.bg2};
        width: 250px;
    }
    .Toastify__toast-body {
        color: ${vars.darkTheme.textSecondary};
    }
    .Toastify__toast--error {
        border: 1px solid ${vars.darkTheme.lossRed_500};
    }
    .Toastify__toast--success {
        border: 1px solid ${vars.darkTheme.winGreen_500};
    }
    .Toastify__toast--warning {
        border: 1px solid ${vars.darkTheme.errorOrange};
    }
    .Toastify__progress-bar--error {
        background: ${vars.darkTheme.lossRed_500};
    }
    .Toastify__progress-bar--success {
        background: ${vars.darkTheme.winGreen_500};
    }
    .Toastify__progress-bar--warning {
        background: ${vars.darkTheme.errorOrange};
    }
`;
