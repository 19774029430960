import { initializeApp } from 'firebase/app'
import { connectAuthEmulator, getAuth } from 'firebase/auth'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'
import { getAnalytics } from 'firebase/analytics'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FB_APIKEY,
    authDomain: process.env.REACT_APP_FB_AUTHDOMAIN,
    projectId: process.env.REACT_APP_FB_PROJECTID,
    storageBucket: process.env.REACT_APP_FB_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_FB_MESSAGINGID,
    appId: process.env.REACT_APP_FB_APPID,
    measurementId: process.env.REACT_APP_FB_MEASUREMENTID,
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

export const fb_authInstance = getAuth(app)
export const fb_functions = getFunctions(app)
export const fb_functions_eu2 = getFunctions(app, 'europe-west2')
export const fb_database = getFirestore(app)
export const fb_analytics = getAnalytics(app)

if (process.env.REACT_APP_DEV_ENV && process.env.REACT_APP_DEV_ENV === 'true') {
    console.info('Connected to dev emulators')
    connectFirestoreEmulator(fb_database, 'localhost', 8080)
    connectFunctionsEmulator(fb_functions, 'localhost', 5001)
    connectFunctionsEmulator(fb_functions_eu2, 'localhost', 5001)
    connectAuthEmulator(fb_authInstance, 'http://localhost:9099')
} else {
    // console.info('Connected to prod');
}
