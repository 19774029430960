import React from 'react';
import ReactDOM from 'react-dom/client';
import App from 'App';
//CONTEXT
import { StateContextProvider } from 'context/ContextProvider';
import { UserAuthContextProvider } from 'context/UserAuthContext';
import { StyledToast } from 'components/Dashboard/utils/Toast/toast';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <>
        {/* <React.StrictMode> */}
        <StateContextProvider>
            <UserAuthContextProvider>
                <StyledToast />
                <App />
            </UserAuthContextProvider>
        </StateContextProvider>
        {/* </React.StrictMode> */}
    </>
);
