import { createContext, useContext, useEffect, useState } from 'react'
import { onAuthStateChanged, signOut } from 'firebase/auth'
import { fb_authInstance } from '../config/configFirebase'

//CONTEXT
const userAuthContext = createContext()

export function UserAuthContextProvider({ children }) {
    const [currentUser, setCurrentUser] = useState()

    function logOut() {
        return signOut(fb_authInstance)
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(fb_authInstance, (user) => {
            if (user !== null) {
                setCurrentUser(user)
            } else {
                setCurrentUser(null)
            }
        })

        return () => {
            unsubscribe()
        } // eslint-disable-next-line
    }, [])

    return (
        <userAuthContext.Provider
            value={{ currentUser, setCurrentUser, logOut }}
        >
            {children}
        </userAuthContext.Provider>
    )
}

export function useUserAuth() {
    return useContext(userAuthContext)
}
