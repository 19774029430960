import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    *, *::before, *::after {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    body {
        background: ${({ theme }) => theme.color.bg0};
        color: ${({ theme }) => theme.color.text};
        font-family: 'Inter Tight', sans-serif;
        font-weight: 300;
    }
`;
