import React from 'react'
import { LoadingSpinner } from './animations/LoadingSpinner'

const LoadingPage = () => {
    return (
        <div
            style={{
                display: 'flex',
                flex: '1 1 auto',
                width: '100%',
                height: '80vh',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <LoadingSpinner />
        </div>
    )
}

export default LoadingPage
